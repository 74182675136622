// Colors -- light theme
$footer-text-color:      #101010;
$footer-border-color:    #404040;
$footer-background-color:#f0f0f0;
$header-text-color:      #101010;
$header-border-color:    #404040;
$header-background-color:#f0f0f0;
$site-text-color:        #010101;
$site-background-color:  #fdfdfd;
$site-date-color:        #508050;
$site-link-color:        #5050F0;
$site-link-color-hover:   lighten($site-link-color, $color-variation);
$site-link-color-visited: darken($site-link-color, $color-variation);
$block-background-color: #d0d0d0;
$block-border-color:     #a0a0a0;

@import
  "color-common" 
;

