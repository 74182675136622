.site-header {
  border-top-width: 0px; 
	border-bottom-width: 4px; 
  border-bottom-style: solid;
  min-height: 60px;
}

.site-title {
  font-size: 30px;
  float: left;
  font-weight: 500;
  font-family: $header-font-family;
  &,
  &:hover {
    text-decoration: none;
  }
}

.site-header .wrapper {
	padding-top: 10px;
	position: relative;
}

.site-nav ul {
	text-align: right;
	float: right;
	font-size: 85%;
	letter-spacing: 1px;
	line-height: 1em;
	font-weight: 600;
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-transform: uppercase;
  font-family: $header-font-family;
}

.site-nav .menu > li {
	border-bottom: 3px solid transparent;
	display: inline-block;
	height: 23px;
	line-height: normal;
	margin: 12px 0 0 20px;
	padding: 0 0 2px 0;
	position: relative;
	vertical-align: top;
}

.site-nav a {
	display: block;
}

/* Sub Menu */

.site-nav .sub-menu a {
	display: block;
	font-weight: normal;
	height: auto;
	letter-spacing: 0;
	line-height: 1.2em;
	padding: 4px 10px;
}

.site-nav  .sub-menu {
	min-width: $header-submenu-size;
	z-index: 10;
}

.site-nav .sub-menu li.current-menu-item > a {
	border-right: 0px;
}


.sub-menu {
	border: 3px solid transparent;
	border-top: 0;
	/* bg and border colors set in header */
	box-shadow: 0 2px 2px rgba(0,0,0,0.4);
	-moz-box-shadow: 0 2px 2px rgba(0,0,0,0.4);
	-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.4);
	display: none;
	right: -10px;
	padding: 4px 0 3px 0;
	position: absolute;
	text-align: left;
	text-transform: none;
	top: 28px;
	min-width: $header-submenu-size;
  z-index: 10;
	font-family: $header-font-family;
}

.sub-menu li {
	border-bottom: 0;
	display: block;
	height: auto;
	margin: 3px 0;
	padding: 0;
	text-align: left;
}

.site-nav li:hover > .sub-menu,
.site-nav li:focus-within > .sub-menu, 
.site-nav li .submenu ul {
	display: block;
}

.site-nav li:focus-within > .sub-menu {
	display: block;
}

.site-nav h1 {
	position: absolute;
	left: -999em;
}

.site-nav {
  float: right;
  line-height: 40px;

  .menu-icon {
    display: none;
  }

  .page-link {
    line-height: $site-line-height;

    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}