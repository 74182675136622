.site-header {
  border-top-color: $header-border-color;
	border-bottom-color: $header-border-color;
  background: $header-background-color;
}

.site-title {
  color: $header-text-color;
  &,
  &:visited {
    color: $header-text-color;
  }
}

.site-nav, .site-nav .trigger {
  border-color: $header-border-color;
}

.site-nav ul li a {
	color: $header-text-color;
}

.site-nav div {
  color: $header-text-color;
}

.site-nav .sub-menu a {
  color: $header-text-color;
}

.site-nav  .sub-menu {
	background: $header-background-color;
	border-color: $header-border-color;
}

.site-nav .sub-menu li.current-menu-item > a {
	border-left-color: $header-border-color;
	border-right-color: $header-border-color;
}

.site-nav .page-link {
    color: $header-text-color;
    background: $header-background-color;
}

.site-nav .menu {
  border-left-color: $header-border-color;
  border-right-color: $header-border-color;
  border-bottom-color: $header-border-color;
}

.site-footer {
  border-top-color: $footer-border-color;
  background-color: $footer-background-color;
}

.date {
  color: $site-date-color;
}

body {
  color: $site-text-color;
  background-color: $site-background-color;
}

a {
  color: $site-link-color;

  &:visited {
    color: $site-link-color-visited; 
  } 

  &:hover {
    color: $site-link-color-hover;
  }
}

blockquote {
  color: $block-background-color;
  border-left-color: $block-border-color;
}

pre,
code {
  border-color: $block-border-color;
  background-color: $block-background-color;
}

@media only screen and (max-width: $screen-small-max) {
  .site-nav {
    border-color: $header-background-color;
  }

  .site-nav .menu li {
    color: $header-text-color;
  }

  .site-nav ul.menu a {
    background: $header-background-color;
    color: $header-text-color;
  }
}