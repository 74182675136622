@charset "utf-8";

$screen-large: 1000px;
$screen-large-max: $screen-large - 1;
$screen-large-min: $screen-large;
$screen-small: 600px;
$screen-small-max: $screen-small - 1;
$screen-small-min: $screen-small;

$color-variation: 25%;

// Fonts
$footer-font-family: Helvetica, Arial, Verdana, sans-serif;
$header-font-family: Helvetica, Arial, Verdana, sans-serif;
$site-font-family: 'Open Sans', Helvetica, Arial, Verdana, sans-serif;

// Fonts size
$site-font-size: 100%;
$site-font-size-small: $site-font-size * 0.875;
$site-line-height: 1.5;

// Page elements size
$header-submenu-size: 200px;
$footer-height: 30px;
$site-indent: 2em;

// Our variables

$spacing-vertical: 20px;

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "color",
  "size",
  "header",
  "footer",
  "page",
  "bibtex",
  "syntax-highlighting",
  "fancy-image"
;
