/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
html {
  overflow-y: scroll;
}

body {
  font-family: $site-font-family;
  font-size: $site-font-size;
  line-height: $site-line-height;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
}

strong {
  font-weight: bold;
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $site-font-size-small;
}

/**
 * Lists
 */
ul, ol {
  margin-left: $site-indent;
}

li {
  > ul,
  > ol {
    @extend %vertical-rhythm;
  }
}

/**
 * Headings
 */
h1 {
  font-size: 2.5em;
  font-weight: bold;
}

h2 {
  font-size: 2em;
  font-weight: bold;
}

h3 {
  font-size: 1.666em;
  font-weight: bold;
}

h4 {
  font-size: 1.333em;
  font-weight: bold;
}

h5, h6 {
  font-size: 1em;
  font-weight: bold;
}

/**
 * Links
 */
a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/**
 * Blockquotes
 */
blockquote {
  border-left: 4px solid;
  padding-left: $site-indent;
  font-size: 1em;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Code formatting
 */
pre,
code {
  font-size: 1em;
  border-width: 1px; 
  border-style: solid;
  border-radius: 3px;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: scroll;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

header {
  > h1 {
    font-style: italic;
  }
  .date {
    float: right;
    font-style: italic;
  }
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-top: $spacing-vertical / 2;
  margin-bottom: $spacing-vertical / 2;
}

/**
 * Site classes
 */
.site-content {
  position: relative;
  padding-bottom: $footer-height;
}

.wrapper {
  margin-right: auto;
  margin-left: auto;
  @extend %clearfix;
}

.portrait {
  width: 120px;
  float: right;
  margin: 8px;
}

.homepage {
  vertical-align: super;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-vertical;
  }
}

.footnotes ol li {list-style-type:decimal;}
.footnotes ol {font-size:.85em; color:#666666;}	
	
.person-image {
  width: 120px;
  float: right;
  margin: 8px;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
