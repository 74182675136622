// Colors -- dark theme
$footer-text-color:      #efefef;
$footer-border-color:    #bfbfbf;
$footer-background-color:#0f0f0f;
$header-text-color:      #efefef;
$header-border-color:    #bfbfbf;
$header-background-color:#0f0f0f;
$site-text-color:        #fefefe;
$site-background-color:  #020202;
$site-date-color:        #0Fafaf;
$site-link-color:        #afaf0f;
$site-link-color-hover:   darken($site-link-color, $color-variation);
$site-link-color-visited: lighten($site-link-color, $color-variation);
$block-background-color: #2f2f2f;
$block-border-color:     #5f5f5f;

@import "color-common";