/**
 * Site footer
 */
.site-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: $footer-height;
  border-top: 1px solid;
  font-family: $footer-font-family;
}

.site-footer .wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer-content {
  float: left;
}

