.site-header {
  border-top-width: 0px; 
	border-bottom-width: 4px; 
  border-bottom-style: solid;
  min-height: 50px;
}

.site-title {
  font-size: 30px;
  float: left;
  font-weight: 500;
  font-family: $header-font-family;
  &,
  &:hover {
    text-decoration: none;
  }
}

.site-header .wrapper {
	padding-top: 0px;
	position: relative;
}

.site-nav {
	position: absolute;
	bottom: 5px;
	right: 15px;
	text-align: right;
	border-width: 3px;
	border-style: solid;
	z-index: 10;

	.menu-icon {
		// display: block;
		float: right;
		text-align: center;
		height: 30px;
		width: 30px;
	}

	.trigger {
		clear: both;
		display: none;
		padding-right: -3px;
		margin-right: -3px;
		border-width: 3px;
		border-style: solid;
		border-left: none;
		border-top: none;
	}

	&:hover {
		border-left-color: transparent;
		border-bottom-color: transparent;
	}

	&:hover .trigger {
		display: block;
		position: absolute;
		top: 0px;
		right: 0px;
		padding-top: 30px;
	}

  .page-link {
		display: block;
		padding: 5px 10px;
    line-height: $site-line-height;
	}

	ul {
		text-align: right;
		float: right;
		font-size: 85%;
		letter-spacing: 1px;
		line-height: 1em;
		font-weight: 600;
		margin: 0;
		padding: 0;
		list-style-type: none;
		text-transform: uppercase;
		font-family: $header-font-family;
	}

	.menu {
		margin-top: 5px;
		border: none;
	}

	.menu li {
		display: block;
		margin: 0;
	}

	a, .site-nav li.current-menu-item > a {
		padding: 4px 10px;
	}

	.menu > li {
		height: auto;
		text-align: left;
	}

	.sub-menu {
		// border: 0;
		-moz-box-shadow: none; /* FF3.5+ */
		-webkit-box-shadow: none; /* Saf3+, Chrome */
		box-shadow: none; /* Standard. Opera 10.5, IE9 */
		display: block;
		font-family: $header-font-family;
		position: relative;
		min-width: $header-submenu-size;
		max-width: $header-submenu-size;
		top: auto;
		text-transform: uppercase;
		right: auto;
		width: auto;
		padding-left: 16px;
		z-index: 10;
	}
	
	.sub-menu a {
		display: block;
		font-weight: normal;
		height: auto;
		letter-spacing: 0;
		line-height: 1.2em;
		padding: 4px 10px;
	}
		
	.sub-menu li.current-menu-item > a {
		border: 0;
	}
}

.site-nav li:hover > .sub-menu,
.site-nav li:focus-within > .sub-menu, 
.site-nav li .submenu ul {
	display: block;
}

