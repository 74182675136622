.bibtex-journal {
  &.authors {
    font-style: normal;
  }
  &.title {
    font-weight: bold;
  }
  &.journal {
    font-style: italic;
  }
}

.bibtex-thesis {
  &.author {
    font-style: normal;
  }
  &.title {
    font-weight: italic;
  }
  &.school {
    font-style: normal;
  }
}

.bibtex-conference {
  &.authors {
    font-style: normal;
  }
  &.title {
    font-weight: bold;
  }
  &.booktitle {
    font-style: italics;
  }
}